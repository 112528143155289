import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import React from 'react';
import CookiesArea from './cookiesPage.style';
const LegalPage = () => {
    return (
        <CookiesArea id="CookiesSection">
            <Container>
            <Heading as="h4" content="POLÍTICA DE COOKIES" />
            <Box className="mt-12 text-left">
                <div className="entry-content">
                    <p className="mt-12 mb-10">Esta web utiliza cookies propias y de terceros para ofrecerle una mejor experiencia y servicio.
                        Al navegar o utilizar nuestros servicios acepta el uso que hacemos de las cookies. Sin embargo,
                        tiene la opción de impedir la generación de cookies y la eliminación de las mismas mediante la
                        selección de la correspondiente opción en su Navegador. En caso de bloquear el uso de cookies en
                        su navegador es posible que algunos servicios o funcionalidades de la página Web no estén
                        disponibles.</p>
                    <p className="mt-12 mb-10"><strong>¿Qué es una cookie?</strong></p>
                    <p className="mt-12 mb-10">Las cookies constituyen una herramienta empleada por los servidores Web para almacenar y
                        recuperar información acerca de sus visitantes. Es un identificador único en forma de fichero de
                        texto que se remite al dispositivo del usuario para registrar información, permitiendo así
                        mejorar la calidad y seguridad de la página web. Poseen una fecha de caducidad a partir de la
                        cual dejan de ser operativas.</p>
                    <p className="mt-12 mb-10"><strong>Utilidad de las cookies en mi página web</strong></p>
                    <p className="mt-12 mb-10">Utilizamos cookies para facilitar la navegación por la web y para obtener una mayor eficacia y
                        personalización de los servicios que le ofrecemos. Las cookies empleadas se asocian únicamente
                        con un Usuario anónimo y su dispositivo, no proporcionan referencias que permitan obtener sus
                        datos personales ni incluir virus en el mismo. Tampoco se pueden leer las cookies implantadas en
                        su dispositivo desde otros servidores. La información que analizamos haciendo uso de las cookies
                        son la dirección de protocolo de internet (IP) empleada para conectar tu ordenador a internet,
                        tipo y versión de navegador, sistema operativo y plataforma utilizada para la conexión y la
                        fecha y la hora de dicha actividad.</p>
                    <p className="mt-12 mb-10"><strong>Cookies empleadas</strong></p>
                    <p className="mt-12 mb-10">Utilizamos cookies permanentes que se almacenan en el dispositivo del usuario y que permiten
                        recuperar la información de la navegación en la siguiente visita. Tienen fecha de caducidad.
                        Estas cookies nos permiten personalizar sus visitas, a través de sus preferencias (por ejemplo,
                        país e idioma).</p>
                    <p className="mt-12 mb-10">También utilizamos cookies de terceros que permiten gestionar y mejorar los servicios ofrecidos.
                        Como por ejemplo, los servicios estadísticos de Google Analytics.</p>
                    <p className="mt-12 mb-10"><strong>Cookies de análisis estadístico y de registro</strong></p>
                    <p className="mt-12 mb-10">Utilizamos cookies permanentes que se almacenan en el dispositivo del usuario y que permiten
                        recuperar la información de la navegación en la siguiente visita. Tienen fecha de caducidad.
                        Estas cookies nos permiten personalizar sus visitas, a través de sus preferencias (por ejemplo,
                        país e idioma).</p>
                    <p className="mt-12 mb-10">También utilizamos cookies de terceros que permiten gestionar y mejorar los servicios ofrecidos.
                        Como por ejemplo, los servicios estadísticos de Google Analytics. A continuación recogemos de
                        forma esquematizada las cookies anteriormente descritas y utilizadas en la página web:</p>
                    <ul>
                        <li><strong>ASP.NET_SessionId, ids, sAct</strong>: Estas cookies son identificadores de sesión
                            proporcionados por el servidor de Microsoft ASP y propio del sistema. Se utilizan para
                            identificar a un usuario que haya iniciado sesión en nuestra página Web. La empresa
                            proveedora de las cookies es <strong>Sirvent Productes.</strong></li>
                        <li><strong>CookiesUserAccepted: </strong>esta cookie se genera de manera automática en el
                            momento en que se acepta la política de cookies de la web, permite que no te mostremos de
                            nuevo el aviso si ya lo has aceptado.
                        </li>
                    </ul>
                    <p className="mt-12 mb-10">&nbsp;</p>
                    <ul>
                        <li><strong>Cookies implementadas por google:</strong>
                            <ul>
                                <li>_<strong>ga</strong>: Se usa para distinguir a los usuarios. (Duración
                                    predeterminada: 2 años)
                                </li>
                                <li>_<strong>gid</strong>: 24 horas&nbsp;&nbsp;&nbsp; Se usa para distinguir a los
                                    usuarios.&nbsp;(Duración predeterminada: 24 horas)
                                </li>
                                <li>_<strong>gat</strong>: Se usa para limitar el porcentaje de solicitudes. Si&nbsp;se
                                    ha&nbsp;implementado Google Analytics mediante Google Tag Manager, esta cookie se
                                    llamará _dc_gtm_&lt;property-id&gt;.&nbsp;(Duración predeterminada: 1 minuto)
                                </li>
                                <li>__<strong>utma:&nbsp;</strong>Se usa para distinguir usuarios y sesiones. La cookie
                                    se crea cuando se ejecuta la biblioteca JavaScript y no hay ninguna cookie (Duración
                                    predeterminada: Dos años a partir de la configuración o actualización.&nbsp;
                                    <strong>__utma:</strong>&nbsp;La cookie se actualiza cada vez que se envían datos a
                                    Google Analytics.
                                </li>
                                <li>__<strong>utmt:&nbsp;</strong>Se usa para limitar el porcentaje de solicitudes.
                                    (Duración predeterminada:&nbsp;10 minutos)
                                </li>
                                <li>__<strong>utmb:</strong>&nbsp;Se usa para determinar nuevas sesiones o visitas. La
                                    cookie se crea cuando se ejecuta la biblioteca JavaScript y no hay ninguna cookie
                                    __utmb. La cookie se actualiza cada vez que se envían datos a Google
                                    Analytics.&nbsp;(Duración predeterminada: 30 minutos a partir de la configuración o
                                    actualización)
                                </li>
                                <li>__<strong>utmc:</strong>&nbsp;No se usa en ga.js. Se configura para interactuar con
                                    urchin.js. Anteriormente, esta cookie actuaba junto con la cookie __utmb para
                                    determinar si el usuario estaba en una nueva sesión o visita.&nbsp;(Duración
                                    predeterminada: Fin de la sesión del navegador )
                                </li>
                                <li>__<strong>utmz:</strong>&nbsp;Almacena la fuente de tráfico o la campaña que explica
                                    cómo ha llegado el usuario al sitio. La cookie se crea cuando se ejecuta la
                                    biblioteca JavaScript y se actualiza cada vez que se envían datos a Google
                                    Analytics.&nbsp;(Duración predeterminada:&nbsp;Seis meses a partir de la
                                    configuración o actualización)
                                </li>
                                <li>__<strong>utmv:&nbsp;</strong>Se usa para almacenar datos de variables
                                    personalizadas de visitante. Esta cookie se crea cuando un programador usa el método
                                    _setCustomVar con una variable personalizada de visitante. También se usaba para el
                                    método _setVar retirado. La cookie se actualiza cada vez que se envían datos a
                                    Google Analytics.&nbsp;(Duración predeterminada: Dos años a partir de la
                                    configuración o actualización)
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p className="mt-12 mb-10"><strong>Cookies publicitarias</strong></p>
                    <p className="mt-12 mb-10">Son aquéllas que permiten gestionar eficazmente los espacios publicitarios de nuestro sitio web y
                        de terceros, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso
                        que realice de nuestra página web.</p>
                    <p className="mt-12 mb-10">Esta página web utiliza la herramienta de remarketing de Google Adwords. Esta herramienta permite
                        publicar en la Red de contenido de Google anuncios basados en visitas anteriores de usuarios a
                        nuestra web. Para conseguirlo, las páginas de nuestro sitio web incluyen un código o etiqueta de
                        remarketing que configuran las cookies para determinar el tipo de anuncio que se mostrará al
                        usuario según la navegación que haya realizado por el sitio web. Las listas de remarketing se
                        guardan en una base de datos de los servidores de Google, donde se almacenan todos los ID de
                        cookie asociados a cada lista o categoría de interés. Respecto a las cookies de remarketing, el
                        usuario puede inhabilitar el uso de cookies de Google, visitando la página de inhabilitación de
                        publicidad de Google. Más Info aquí:
                        <a href="http://www.google.es/intl/es/policies/technologies/ads/" target="_blank" rel="noopener noreferrer">
                            http://www.google.es/intl/es/policies/technologies/ads/
                        </a>
                    </p>
                    <p className="mt-12 mb-10"><strong>Cookie de publicidad de DoubleClick (Google)</strong></p>
                    <p className="mt-12 mb-10">DoubleClick es un servicio de anuncios de terceros para algunas páginas web. En&nbsp;<a
                        href="http://www.google.es/intl/es/policies/technologies/ads/" rel="noopener noreferrer"
                        target="_blank">http://www.google.es/intl/es/policies/technologies/ads/</a>&nbsp;puedes ver la
                        política de privacidad. DoubleClick usa cookies para mejorar la publicidad. Suelen utilizarse
                        para orientar la publicidad según el contenido que es relevante para un usuario, mejorar los
                        informes de rendimiento de la campaña y evitar mostrar anuncios que el usuario ya haya visto.
                        Las cookies de DoubleClick no contienen información de identificación personal. En ocasiones, la
                        cookie contiene un identificador adicional que se parece al ID de cookie. Este identificador se
                        emplea para identificar una campaña publicitaria a la que un usuario se ha expuesto
                        anteriormente; pero DoubleClick no guarda otros datos en la cookie y ningún dato permite la
                        identificación personal.</p>
                    <p className="mt-12 mb-10">Para permitir, conocer, bloquear o eliminar las cookies instaladas en tu equipo puedes hacerlo
                        mediante la configuración de las opciones del navegador instalado en tu ordenador.</p>
                    <p className="mt-12 mb-10">Por ejemplo puedes ver cómo hacerlo en las ayudas facilitadas por cada navegador dependiendo de
                        cual utilices:</p>
                    <ul>
                        <li>Firefox desde aquí:&nbsp;<a
                            href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"
                            target="_blank" rel="noopener noreferrer">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a>
                        </li>
                        <li>Chrome desde aquí:&nbsp;<a
                            href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647"
                            target="_blank" rel="noopener noreferrer">http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</a>
                        </li>
                        <li>Explorer desde aquí:&nbsp;<a
                            href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"
                            target="_blank" rel="noopener noreferrer">http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</a>
                        </li>
                        <li>Safari desde aquí:&nbsp;<a href="http://support.apple.com/kb/ph5042" rel="noopener noreferrer"
                                                       target="_blank">http://support.apple.com/kb/ph5042</a></li>
                        <li>Opera desde aquí:&nbsp;<a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html"
                                                      target="_blank" rel="noopener noreferrer">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a>
                        </li>
                    </ul>
                    <p className="mt-12 mb-10"><strong>¿Qué ocurre si se deshabilitan las cookies?</strong></p>
                    <p className="mt-12 mb-10">En el caso de bloquear o no aceptar la instalación de cookies es posible que ciertos servicios
                        ofrecidos por nuestro sitio web que necesitan su uso queden deshabilitados y, por lo tanto, no
                        estén disponibles para usted por lo que no podrá aprovechar por completo todo lo que nuestras
                        webs y aplicaciones le ofrecen. Es posible también que la calidad de funcionamiento de la página
                        web pueda disminuir.</p>
                    <p className="mt-12 mb-10"><strong>Actualizaciones en la Política de Cookies</strong></p>
                    <p className="mt-12 mb-10">Nuestra página web puede modificar esta Política de Cookies en función de exigencias
                        legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones
                        dictadas por la Agencia Española de Protección de Datos, por lo que se aconseja a los usuarios
                        que la visiten periódicamente.</p>
                    <p className="mt-12 mb-10">&nbsp;</p>
                </div>
            </Box>
          </Container>
        </CookiesArea>
  );
};

export default LegalPage;
